<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <v-card grid-list-lg>
      <ModelTitle title="Edit Category" @close="close()" />

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <ImageView
              :name="searchName"
              field="cateogry"
              @submit="imageChange"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :error-messages="nameErrors"
              :hide-details="nameErrors.length === 0"
              v-model="Category.name"
              ref="categoryName"
              @change="changeName"
              outlined
              @focus="$event.target.select()"
              dense
              label="Name"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              counter="counter"
              @focus="$event.target.select()"
              auto-grow
              outlined
              hide-details
              dense
              v-model="Category.note"
              :label="$t('labels.description')"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
              color="yellow darken-1"
              v-model="Category.remoteOrder"
              label="Remote Ordering"
              class="mt-2"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
              color="yellow darken-1"
              v-model="Category.catalog"
              label="Catalog Ordering"
              class="mt-2"
            >
            </v-switch>
          </v-col>
          <v-col cols="12" md="6">
            <v-switch  color="yellow darken-1"
              v-model="Category.applyMarkup"
              label="Apply Markup"
              class="mt-2"
            >
            </v-switch>
          </v-col>
					<span v-if="Category.applyMarkup">
					<v-row>
						<v-col cols="12" md="7" class="ml-2">
							<v-switch  color="yellow darken-1"
								v-model="Category.forceApplyMarkup"
								label="Force Apply Markup on all products"
								class="mt-2"
							>
							</v-switch>
						</v-col>
						<v-col cols="12" md="4" class="mr-1 mt-2">
							<v-text-field
								counter="counter"
								@focus="$event.target.select()"
								auto-grow
								outlined
								hide-details
								dense
								v-model="Category.markupPercentage"
								label="Mark up %"
							>
							</v-text-field>
						</v-col>
						</v-row>
					</span>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryService from "../service.js";
import ImageView from "@/components/Image/ImageView";
import GlobalFunction from "@/mixins/GlobalFunction";
import _ from "lodash";
const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  props: {
    editDialog: {
      default: false,
    },
    editCategory: {
      type: Object,
    },
  },
  components: {
    ImageView,
  },
  name: "update-Category",
  data() {
    return {
      searchName: null,
      loading: false,
      dialog: false,
      Category: {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null,
				applyMarkup: false,
				markupPercentage: 0,
				forceApplyMarkup: false,
      },
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Category: {
      name: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Category.name.$dirty) return errors;
      if (!this.$v.Category.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    },
  },
  watch: {
    editDialog(val) {
      this.Category = _.clone(this.editCategory);
			this.Category.forceApplyMarkup = false
      this.dialog = val;
      this.$nextTick(() => {
				if (this.$refs.categoryName) this.$refs.categoryName.focus();
      });
    },
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Category = {
        name: null,
        imageUrl: null,
        note: null,
				applyMarkup: false,
				markupPercentage: 0,
				forceApplyMarkup: false,
      };
      this.$v.$reset();
    },
    imageChange(val) {
      if (val) {
        this.Category.imageUrl = val;
      }
    },
    changeName() {
      this.Category.name = this.properCase(this.Category.name);
      this.searchName = this.Category.name;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
				if (this.Category.applyMarkup && !this.Category.markupPercentage) {
        return this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: "Mark up percentage is required"
          });
				}
        this.loading = true;
        return CategoryService.update(this.Category.id, this.Category)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Category is updated",
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
